import { useState } from "react";
import styles from "./Dice.module.scss";

function getRandomInt(ceil) {
  return Math.ceil(Math.random() * ceil);
}

export default function Dice({ ceil }) {
  const [animation, setAnimation] = useState("wow");
  const [disabled, setDisabled] = useState(false);
  const [number, setNumber] = useState(1);

  function handleClick() {
    setDisabled(true);
    setAnimation(animation === "wee" ? "woo" : "wee");
    setTimeout(function() {
      setNumber(getRandomInt(ceil));
      setDisabled(false);
    }, 300);
  }
  return (
    <button className={styles.button} disabled={disabled} onClick={handleClick}>
      <span className={`${styles.number} ${styles[animation]}`}>{number}</span>
    </button>
  );
}
