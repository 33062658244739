import { useState } from "react";
import styles from "./Instructions.module.scss";

// Rules.
// Players roll to progress.
// If a player is wants to TELEPORT onto the same tile as another player
//  both players roll the health check. Else abandon turn.
// If a player TELEPORTs onto the same square as a RIFT. Roll the dice and move that many steps backwards.
// If a RIFT lands you on the same square as another player roll a health check.
// When one player remains the game is over else the first player off the board wins.

function Content({ handleNext, number }) {
  if (number === 1) {
    return (
      <div>
        <p className={styles.instruction}>
          Welcome to RIFTS AND TELEPORTERS! I'm RIFT DUDE! You are going to PLAY
          as a TELEPORTER! Sounds fun huh!
        </p>
        <button className={styles.button} onClick={handleNext}>
          Next
        </button>
      </div>
    );
  }
  if (number === 2) {
    return (
      <div>
        <p className={styles.instruction}>
          To start press on the LOCATION button (has the word LOCATION written
          under it) on the right.
        </p>
        <button className={styles.button} onClick={handleNext}>
          Next
        </button>
      </div>
    );
  }
  if (number === 3) {
    return (
      <div>
        <p className={styles.instruction}>
          Wow! you got the LOCATION of a square. The idea is you keep pressing
          that and drag the RIFTS on the left (GREEN and RED) on to those
          LOCATIONs. If a LOCATION already has a RIFT, press for a new LOCATION.
        </p>
        <button className={styles.button} onClick={handleNext}>
          Next
        </button>
      </div>
    );
  }
  if (number === 4) {
    return (
      <div>
        <p className={styles.instruction}>
          Cool! Now that you got that. The next part is to choose a character
          from the top right.
        </p>
        <button className={styles.button} onClick={handleNext}>
          Next
        </button>
      </div>
    );
  }
  if (number === 5) {
    return (
      <div>
        <p className={styles.instruction}>
          Now press the TELEPORT button. That number is the LOCATION you start
          on. Drag your character onto that LOCATION.
        </p>
        <button className={styles.button} onClick={handleNext}>
          Next
        </button>
      </div>
    );
  }
  if (number === 6) {
    return (
      <div>
        <p className={styles.instruction}>
          Now you are good to start TELEPORTERING! Take it in turns to press the
          TELEPORT button!
        </p>
        <button className={styles.button} onClick={handleNext}>
          Next
        </button>
      </div>
    );
  }
  if (number === 7) {
    return (
      <div>
        <p className={styles.instruction}>
          If you land on a GREEN RIFT, press TELEPORT again! If you land on a
          RED RIFT something has gone wrong! Click on TELEPORT and move that
          many LOCATIONS back from the RED RIFT.
        </p>
        <button className={styles.button} onClick={handleNext}>
          Next
        </button>
      </div>
    );
  }
  if (number === 8) {
    return (
      <div>
        <p className={styles.instruction}>First to pass LOCATION 60 wins!</p>
        <button className={styles.button} onClick={handleNext}>
          Next
        </button>
      </div>
    );
  }
  return null;
}

export default function Dice({ ceil }) {
  const [number, setNumber] = useState(1);

  function handleNext() {
    setNumber(number + 1);
  }
  if (number > 8) return null;
  return (
    <div className={styles.container}>
      <div className={`${styles.instructor} ${styles.animate}`} />
      <Content handleNext={handleNext} number={number} />
    </div>
  );
}
