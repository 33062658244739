const grid = [
  ["60", "59", "58", "57", "56", "55", "54", "53", "52", "51"],
  ["41", "42", "43", "44", "45", "46", "47", "48", "49", "50"],
  ["40", "39", "38", "37", "36", "35", "34", "33", "32", "31"],
  ["21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
  ["20", "19", "18", "17", "16", "15", "14", "13", "12", "11"],
  ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],
];

export default grid;
