import { Fragment, useRef } from "react";
import Draggable from "react-draggable";

import Dice from "./Dice";
import Instructions from "./Instructions";

import grid from "./grid";

import layer0 from "./layer-0.svg";
import layer1 from "./layer-1.svg";
import layer2 from "./layer-2.svg";
import layer3 from "./layer-3.svg";
import layer4 from "./layer-4.svg";
import layer5 from "./layer-5.svg";

function Square({ className, number }) {
  return (
    <div className={`square square-${className}`}>
      <span className="square-label">{number}</span>
    </div>
  );
}

function Squares() {
  return (
    <>
      {grid.map((row, rowIndex) => {
        return (
          <Fragment key={rowIndex}>
            {row.map((square, squareIndex) => {
              const key = `${rowIndex}-${squareIndex}`;
              return <Square className={key} key={key} number={square} />;
            })}
          </Fragment>
        );
      })}
    </>
  );
}

export default function App() {
  const nodeRef = useRef(null);
  return (
    <div className="center">
      <div className="board">
        <Instructions />
        <div className="container">
          <header className="header">
            <h1>Rifts and teleporters</h1>
          </header>
          <div className="players">
            <Draggable grid={[8, 8]} handle=".player" nodeRef={nodeRef}>
              <div
                className="player"
                ref={nodeRef}
                style={{ backgroundImage: `url(${layer3})` }}
              />
            </Draggable>
            <Draggable grid={[8, 8]} handle=".player" nodeRef={nodeRef}>
              <div
                className="player"
                ref={nodeRef}
                style={{ backgroundImage: `url(${layer4})` }}
              />
            </Draggable>
            <Draggable grid={[8, 8]} handle=".player" nodeRef={nodeRef}>
              <div
                className="player"
                ref={nodeRef}
                style={{ backgroundImage: `url(${layer5})` }}
              />
            </Draggable>
          </div>
          {/*<p>Roll twice to get the X and Y coordinates of the encounters.</p>
        <p>Roll twice to get the X and Y coordinates of the rifts.</p>
        <p>
          Once locations of the rifts have been defined. Players take turns to
          roll the dice placing their characters on this square. If that square
          is occupied roll again.
        </p>*/}
        </div>
        <div className="container">
          <div className="pieces">
            <Draggable grid={[8, 8]} handle=".piece" nodeRef={nodeRef}>
              <div
                className="piece"
                ref={nodeRef}
                style={{ backgroundImage: `url(${layer1})` }}
              />
            </Draggable>
            <Draggable grid={[8, 8]} handle=".piece" nodeRef={nodeRef}>
              <div
                className="piece"
                ref={nodeRef}
                style={{ backgroundImage: `url(${layer1})` }}
              />
            </Draggable>
            <Draggable grid={[8, 8]} handle=".piece" nodeRef={nodeRef}>
              <div
                className="piece"
                ref={nodeRef}
                style={{ backgroundImage: `url(${layer1})` }}
              />
            </Draggable>
            <Draggable grid={[8, 8]} handle=".piece" nodeRef={nodeRef}>
              <div
                className="piece"
                ref={nodeRef}
                style={{ backgroundImage: `url(${layer2})` }}
              />
            </Draggable>
            <Draggable grid={[8, 8]} handle=".piece" nodeRef={nodeRef}>
              <div
                className="piece"
                ref={nodeRef}
                style={{ backgroundImage: `url(${layer2})` }}
              />
            </Draggable>
            <Draggable grid={[8, 8]} handle=".piece" nodeRef={nodeRef}>
              <div
                className="piece"
                ref={nodeRef}
                style={{ backgroundImage: `url(${layer2})` }}
              />
            </Draggable>
          </div>
          <div className="squares">
            <Squares />
          </div>
          <div className="controls">
            <div
              style={{
                backgroundColor: "#77ffff",
                backgroundImage: `url(${layer0})`,
              }}
            >
              <Dice ceil={6} />
            </div>
            <p className="controls-label">TELEPORT</p>
            <div style={{ backgroundColor: "#ff7777" }}>
              <Dice ceil={60} />
            </div>
            <p className="controls-label">LOCATION</p>
          </div>
        </div>
      </div>
    </div>
  );
}
